/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Button } from "@material-ui/core";
import { ChevronLeft, ChevronRight, Check, AlertTriangle } from "react-feather";

const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 2px;
  margin-bottom: 4px:
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px:
  background: transparent;
  padding: 0px 4px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;


  @media(max-width: 800px) {
    gap: 2px;
  }
`;

const Fifth = styled.div`
  flex: 0.2;
  height: 36px;
  border: 1px solid ${(props) =>
    props.$isActive ? "lightgray" : "transparent"};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.$isActive ? "white" : "#eeeeee")};
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  flex-grow: 1;
  color: ${(props) => (props.$isActive ? "black" : "slategrey")};
  transition: background 200ms, color 300ms, border 200ms;
  }

  span {
    font-size: 13px;
  }

  @media(max-width: 600px) {
    span {
      font-size: 10px;
    }
  }
`;

const CustomButton = styled(Button)`
  width: 36px;
  height: 36px;
  max-height: 36px;
  max-width: 36px;
  min-height: 36px;
  min-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  background: white;
  border: 1px solid lightgray;
  transition: background 200ms ease-in;

  svg {
    color: slategrey;
  }

  &:hover {
    background: #eeeeee;
    svg {
      color: black;
    }
  }
`;

const CompletedWrapper = styled.div`
  height: 14px;
  width: 14px;
  padding: 2px;
  margin-right: 8px;
  border-radius: 4px;
  background: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -6px;

  @media (max-width: 700px) {
    display: none;
  }
`;

const CompletedIndicator = ({ completed }) => {
  return (
    <CompletedWrapper>
      {completed ? (
        <Check height={10} width={10} color="mediumseagreen" />
      ) : (
        <AlertTriangle height={10} width={10} color="darkorange" />
      )}
    </CompletedWrapper>
  );
};

const ModuleStepper = ({ steps, stepIndex, setStepIndex }) => {
  return (
    <Wrapper>
      <CustomButton
        onClick={() => setStepIndex((p) => p - 1)}
        disabled={stepIndex === 0}
      >
        <ChevronLeft />
      </CustomButton>
      <div style={{ width: 4 }} />
      {steps.map((step, i) => (
        <Fifth
          key={step.label}
          $isActive={stepIndex === i}
          onClick={() => setStepIndex(i)}
        >
          {i !== 4 && (
            <CompletedIndicator
              completed={step.complete}
              isActive={stepIndex === i}
            />
          )}
          <span>{i !== 4 ? step.label : "Launch"}</span>
        </Fifth>
      ))}
      <div style={{ width: 4 }} />

      <CustomButton
        onClick={() => setStepIndex((p) => p + 1)}
        disabled={stepIndex + 1 === steps.length}
      >
        <ChevronRight />
      </CustomButton>
    </Wrapper>
  );
};

export default ModuleStepper;

ModuleStepper.propTypes = {
  stepIndex: PropTypes.number.isRequired,
  setStepIndex: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
};
