/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 240px;
  gap: 2px;
  padding: 6px 0px;
  overflow: scroll;
`;

const ListItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: flex-start;
  padding: 1px;
`;

const CurrentCompanyDisplay = ({ user, currentCompany }) => {
  const accessLevel =
    user?.job === "M"
      ? "Supervisor Access"
      : user?.requesters_access_in_current_environment.find(
          (c) => c?.company_id === currentCompany?.id,
        )?.requesters_access === "WRITE"
      ? "Write Access"
      : "Read Only Access";

  return (
    <>
      <p
        style={{
          fontSize: 11,
          color: "slategrey",
          fontWeight: "600",
          textTransform: "uppercase",
        }}
      >
        Current Company
      </p>
      <ListContainer>
        <ListItem>
          <img
            style={{
              objectFit: "contain",
              height: 38,
              width: 38,
              borderRadius: 8,
              border: "1px solid #eeeeee",
            }}
            src={currentCompany?.logo}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: 12, fontWeight: 600 }}>
              {currentCompany?.name}
            </span>
            <span style={{ fontSize: 11, color: "darkgrey" }}>
              {accessLevel}
            </span>
          </div>

          <div style={{ marginLeft: "auto" }} />
        </ListItem>
        {/* <span style={{ fontSize: 10, color: "slategrey" }}>
          Admin Access at {filteredCompanies.length} other companies
        </span> */}

        {/* {filteredCompanies?.length > 0 ? (
          filteredCompanies.map((company) => (
            <ListItem key={company?.company_id}>
              <span style={{ fontSize: 11, fontWeight: 400 }}>
                {company?.company_name}
              </span>
              <div style={{ marginLeft: "auto" }} />
              {company?.requesters_access === "WRITE" ? (
                <WriteChip small />
              ) : (
                <ReadChip small />
              )}
            </ListItem>
          ))
        ) : (
          <div />
        )} */}
      </ListContainer>
    </>
  );
};

export default CurrentCompanyDisplay;
