/* eslint-disable */
import { Button, Paper } from "@material-ui/core";
import React from "react";
import { Printer } from "react-feather";
import QRCode from "react-qr-code";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Link as LinkIcon } from "react-feather";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: 340px;
  max-height: 98vh;
  min-height: 460px;
  position: absolute;
  left: 50%;
  overflow-y: scroll;
  border: 2px solid black;
  background: #eeeeee;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  @media print {
    width: fit-content;
    border: 2px solid transparent;
    box-shadow: none;
  }
`;

const LocationName = styled.span`
  font-size: 18px;
  font-weight: 700;

  @media print {
    margin-bottom: 12px;
    font-size: 26px;
  }
`;

const QrWrapper = styled.div`
  height: 240px;
  width: 240px;
  background: white;
  border-radius: 4px;
  padding: 12px;
  border: 1px solid lightgray;

  @media print {
    height: 460px;
    width: 460px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 4px;

  @media print {
    display: none;
  }
`;

const TextWrapper = styled.div`
  min-height: 120px;
  width: 290px;
  padding: 8px;
  border-radius: 4px;
  text-align: center;

  @media print {
    display: none;
  }
`;

const BASE_URL = "https://learn.withspiffy.com/register/?";

const QrCodeModal = ({ locationName, locationId, teamId, teamName }) => {
  const params = {
    team: Number(teamId),
    teamName,
    locationName,
    location: Number(locationId),
  };

  const paramsObj = new URLSearchParams(params);

  const paramsString = paramsObj.toString();

  const FULL_LINK_URL = `${BASE_URL}${paramsString}`;

  return (
    <ModalWrapper>
      <LocationName>{locationName}</LocationName>
      <div style={{ height: 12 }} />
      <QrWrapper>
        <QRCode
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={FULL_LINK_URL}
        />
      </QrWrapper>
      <div style={{ height: 16 }} />
      <ButtonWrapper>
        <Button
          component={Link}
          exact
          startIcon={<LinkIcon height={10} width={10} />}
          size="small"
          variant="outlined"
          style={{ background: "white" }}
          to={{ pathname: FULL_LINK_URL }}
          target="_blank"
        >
          Visit Link
        </Button>
        <Button
          startIcon={<Printer height={10} width={10} />}
          size="small"
          variant="outlined"
          style={{ background: "white" }}
          onClick={() => window.print()}
        >
          Print QR
        </Button>
      </ButtonWrapper>
      <div style={{ height: 8 }} />
      <TextWrapper>
        <p style={{ fontSize: 12 }}>
          Expedite your Team Members onboarding by getting them to scan this QR
          code to join your team.
        </p>
        <div style={{ height: 12 }} />
        <p style={{ fontSize: 12 }}>
          If you have turned 'Auto-Accept Team Members' "OFF", make sure you
          accept the team member requests from the team members page.
        </p>
      </TextWrapper>
    </ModalWrapper>
  );
};

export default QrCodeModal;
