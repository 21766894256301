/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import { AUDIENCETYPES } from "../../../storage/constants";

// Currently only used in add audience and date to launch from marketplace //

const SelectableButton = styled.div`
  flex: 0.33;
  height: 34px;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 18px;
  border: ${(props) =>
    props.$isSelected ? "1px solid slategrey" : "1px solid lightgray"};
  border-radius: 4px;
  background-color: white;
  cursor: pointer;

  font-weight: 600;
  font-size: 12px;
  transition: all 140ms ease-in;

  &:hover {
    background-color: #eeeeee;
  }
`;

const SelectedIndicator = styled.div`
  height: 13px;
  width: 13px;
  min-width: 13px;
  min-height: 13px;
  background: ${(props) => (props.$isSelected ? "#337AB7" : "lightgray")};
  margin-right: 8px;
  border-radius: 4px;
`;

const AudienceButtons = ({
  setSelectedAudience,
  setComboValue,
  setLocationModalOpen,
  setRoleModalOpen,
  selectedAudience,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: 8,
      }}
    >
      <SelectableButton
        $isSelected={selectedAudience === AUDIENCETYPES.SELECT_ALL}
        onClick={() => {
          setSelectedAudience(AUDIENCETYPES.SELECT_ALL);
          setComboValue([]);
        }}
      >
        <SelectedIndicator
          $isSelected={selectedAudience === AUDIENCETYPES.SELECT_ALL}
        />
        <span>All Team Members</span>
      </SelectableButton>
      <SelectableButton
        $isSelected={selectedAudience === AUDIENCETYPES.LOCATIONS}
        onClick={() => {
          setSelectedAudience(AUDIENCETYPES.NONE);
          setComboValue([]);
          setLocationModalOpen(true);
        }}
      >
        <SelectedIndicator
          $isSelected={selectedAudience === AUDIENCETYPES.LOCATIONS}
        />

        <span>By Locations</span>
      </SelectableButton>
      <SelectableButton
        $isSelected={selectedAudience === AUDIENCETYPES.ROLES}
        onClick={() => {
          setSelectedAudience(AUDIENCETYPES.NONE);
          setComboValue([]);
          setRoleModalOpen(true);
        }}
      >
        <SelectedIndicator
          $isSelected={selectedAudience === AUDIENCETYPES.ROLES}
        />
        <span>By Roles</span>
      </SelectableButton>
    </div>
  );
};

export default AudienceButtons;
