import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useManagerPromotionOptions = (id) => {
  const { data, error, mutate } = useSWR(
    `dashboard/managers/promote/?id=${id}`,
    fetcher,
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useManagerPromotionOptions;
