/* eslint-disable */
import React from "react";
import {
  LIBRARY_MODAL_KEYS,
  QUIZSTATES,
  USER_TYPES,
} from "../../../../../storage/constants";
import { StyledItem } from "../styles";
import API from "../../../../../axios/instances/API";
import { Send } from "react-feather";

const OrganizationCopy = ({
  id,
  userType,
  moduleState,
  setModalState,
  handleClose,
  isRetailerModuleFromMarketplace,
}) => {
  if (isRetailerModuleFromMarketplace) {
    return null;
  }

  if (![USER_TYPES.RETAIL_ADMIN].includes(userType)) return null;

  if (moduleState === QUIZSTATES.CONVERSION) return null;

  return (
    <StyledItem
      onClick={() => {
        setModalState({ key: LIBRARY_MODAL_KEYS.ORG_SHARE, id });
        handleClose();
      }}
    >
      <Send />
      <span>Share within Organization</span>
    </StyledItem>
  );
};

export default OrganizationCopy;
