import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useAdminTeam = () => {
  const { data, error, mutate } = useSWR("dashboard/admin/list/", fetcher, {
    revalidateOnFocus: false,
  });

  return {
    admins: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useAdminTeam;
