/* eslint-disable */
import Home from "../pages/private/home";
import Admin from "../pages/private/admin";
import Locations from "../pages/private/locations";
import LocationDetail from "../pages/private/locations/locationdetail";
import TeamMembers from "../pages/private/teamMembers";
import Supervisors from "../pages/private/supervisors";
import Library from "../pages/private/library";
import Roles from "../pages/private/roles";
import Reports from "../pages/private/reports";
import SignIn from "../pages/public/SignIn";
import Register from "../pages/public/Register";
import RecoverPassword from "../pages/public/auth/RecoverPassword";
import ResetPassword from "../pages/public/auth/ResetPassword";
import AcceptInvite from "../pages/public/Invites";
import Contact from "../pages/public/auth/Contact";
import ModuleBuilder from "../pages/private/moduleBuilder";
import Streams from "../pages/private/streams";
import RoleDetails from "../pages/private/roles/detail";
import SupervisorsDetail from "../pages/private/supervisors/detail";
import TeamMemberDetail from "../pages/private/teamMembers/detail";
import StreamDetail from "../pages/private/streams/detail";
import Audience from "../pages/private/audience";
import AudienceDetail from "../pages/private/audience/detail";
import MarketPlace from "../pages/private/marketplace";
import ValidateEmail from "../pages/public/ValidateEmail";
import EngagedUsers from "../pages/private/engagedUsers";
import ModuleProgress from "../pages/private/reports/ModuleProgress";
import SingleCompanyBreakdown from "../pages/private/reports/vendorReports/SingleCompanyBreakdown";
import SingleLocationBreakdown from "../pages/private/reports/vendorReports/SingleLocationBreakdown";
import SingleUserBreakdown from "../pages/private/reports/vendorReports/SingleUserBreakdown";
import Referral from "../pages/public/Referral";
import EmailPreferences from "../pages/public/EmailPreferences";

const routes = [
  {
    path: "/",
    component: SignIn,
    needsAuth: false,
    exact: true,
    allowManager: true,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/register/",
    component: Register,
    needsAuth: false,
    exact: false,
    allowManager: true,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/contact/",
    component: Contact,
    needsAuth: false,
    exact: true,
    allowManager: true,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/recover-password/",
    component: RecoverPassword,
    needsAuth: false,
    exact: true,
    allowManager: true,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/verify/:token/",
    component: ValidateEmail,
    needsAuth: false,
    exact: false,
    allowManager: true,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/password-reset/:token/",
    component: ResetPassword,
    needsAuth: false,
    exact: false,
    allowManager: true,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/invitation/:token/",
    component: AcceptInvite,
    needsAuth: false,
    exact: false,
    allowManager: true,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/home/",
    component: Home,
    needsAuth: true,
    exact: true,
    allowManager: true,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/admin/",
    component: Admin,
    needsAuth: true,
    exact: true,
    allowManager: false,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/locations/",
    component: Locations,
    needsAuth: true,
    exact: true,
    allowManager: true,
    allowVendor: false,
    allowRetail: true,
  },
  {
    path: "/locations/detail/:id",
    component: LocationDetail,
    needsAuth: true,
    exact: false,
    allowManager: true,
    allowVendor: false,
    allowRetail: true,
  },
  {
    path: "/teammembers/",
    component: TeamMembers,
    needsAuth: true,
    exact: true,
    allowManager: true,
    allowVendor: false,
    allowRetail: true,
  },
  {
    path: "/teammembers/detail/:id",
    component: TeamMemberDetail,
    needsAuth: true,
    exact: false,
    allowManager: true,
    allowVendor: false,
    allowRetail: true,
  },
  {
    path: "/supervisors/",
    component: Supervisors,
    needsAuth: true,
    exact: true,
    allowManager: false,
    allowVendor: false,
    allowRetail: true,
  },
  {
    path: "/supervisors/detail/:id",
    component: SupervisorsDetail,
    needsAuth: true,
    exact: false,
    allowManager: false,
    allowVendor: false,
    allowRetail: true,
  },
  {
    path: "/roles/",
    component: Roles,
    needsAuth: true,
    exact: true,
    allowManager: true,
    allowVendor: false,
    allowRetail: true,
  },
  {
    path: "/roles/details/:id",
    component: RoleDetails,
    needsAuth: true,
    exact: true,
    allowManager: false,
    allowVendor: false,
    allowRetail: true,
  },
  {
    path: "/audience/",
    component: Audience,
    needsAuth: true,
    exact: true,
    allowManager: false,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/audience/detail/:id",
    component: AudienceDetail,
    needsAuth: true,
    exact: true,
    allowManager: false,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/modules/",
    component: Library,
    needsAuth: true,
    exact: true,
    allowManager: true,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/streams/",
    component: Streams,
    needsAuth: true,
    exact: true,
    allowManager: false,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/streams/details/:id",
    component: StreamDetail,
    needsAuth: true,
    exact: true,
    allowManager: false,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/modules/quiz-builder/:id",
    component: ModuleBuilder,
    needsAuth: true,
    exact: true,
    allowManager: true,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/modules/progress/:id",
    component: ModuleProgress,
    needsAuth: true,
    exact: true,
    allowManager: true,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/reports/",
    component: Reports,
    needsAuth: true,
    exact: true,
    allowManager: true,
    allowVendor: true,
    allowRetail: true,
  },
  {
    path: "/marketplace/",
    component: MarketPlace,
    needsAuth: true,
    exact: false,
    allowManager: true,
    allowVendor: false,
    allowRetail: true,
  },
  {
    path: "/engaged-users/",
    component: EngagedUsers,
    needsAuth: true,
    exact: true,
    allowManager: false,
    allowVendor: true,
    allowRetail: false,
  },
  {
    path: "/vendor-reports/company/:id",
    component: SingleCompanyBreakdown,
    needsAuth: true,
    exact: true,
    allowManager: false,
    allowRetail: false,
    allowVendor: true,
  },
  {
    path: "/vendor-reports/location/:id",
    component: SingleLocationBreakdown,
    needsAuth: true,
    exact: true,
    allowManager: false,
    allowRetail: false,
    allowVendor: true,
  },
  {
    path: "/vendor-reports/user/:id",
    component: SingleUserBreakdown,
    needsAuth: true,
    exact: true,
    allowManager: false,
    allowRetail: false,
    allowVendor: true,
  },
  {
    path: "/referral/:ref",
    component: Referral,
    needsAuth: false,
    exact: false,
    allowManager: true,
    allowRetail: true,
    allowVendor: true,
  },
  {
    path: ["/email-preferences/", "/email-preferences/:email"],
    component: EmailPreferences,
    needsAuth: false,
    exact: true,
    allowManager: true,
    allowRetail: true,
    allowVendor: true,
  },
];

export default routes;
