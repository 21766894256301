/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import { TABLE_STATES, getComboIds } from "../helpers";
import useAudienceToolStore from "../../../../store/useAudienceToolStore";
import { AUDIENCETYPES, USER_TYPES } from "../../../../storage/constants";
import CurrentAudience from "../../currentAudience";
import SupervisorModifyToggle from "../SupervisorModifyToggle";
import FunLoader from "../../../Loaders/FunLoader";

const Right = styled.div`
  padding: 2px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 6px;
  border: 3px solid ${(props) => (props.$isSaving ? "#eeeeee" : "black")};

  flex-basis: 420px;
  max-width: 440px;
  min-width: 360px;
  height: 320px;
`;

const AdminBox = () => {
  const tableState = useAudienceToolStore((state) => state.tableState);
  const currentAudienceType = useAudienceToolStore(
    (state) => state.currentAudienceType,
  );

  const currentAudience = useAudienceToolStore(
    (state) => state.currentAudience,
  );

  const locations = useAudienceToolStore((state) => state.locations);
  const roles = useAudienceToolStore((state) => state.roles);
  const comboIds = getComboIds(currentAudienceType, currentAudience);

  const moduleId = useAudienceToolStore((state) => state.moduleId);

  const userIsManager = useAudienceToolStore(
    (state) => state.userType === USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY,
  );
  return (
    <Right $isSaving={tableState === TABLE_STATES.SAVING}>
      <div
        style={{
          display: "flex",

          height: 256,
          width: "100%",

          maxWidth: 460,
          background: "white",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          borderRadius: 4,
          padding: 12,
        }}
      >
        <div
          style={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",

            justifyContent: "flex-start",
            gap: 3,
          }}
        >
          <p
            style={{
              fontWeight: "600",
              fontSize: 14,
              textTransform: "uppercase",
              padding: 2,
              color: "black",
              // background: "#62c06d3b",
              borderRadius: 4,
            }}
          >
            {currentAudienceType === AUDIENCETYPES.NONE
              ? "No Audience"
              : "Saved Audience"}
          </p>
        </div>
        <div style={{ height: 10 }} />
        {tableState === TABLE_STATES.SAVING ? (
          <div
            style={{
              height: 200,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid slategrey",
              borderRadius: 4,
              background: "#eeeeee34",
            }}
          >
            <FunLoader />
          </div>
        ) : (
          <CurrentAudience
            moduleId={moduleId}
            options={{ locations, roles }}
            comboValue={comboIds}
            shouldTableBeViewable={true}
            selectedAudience={
              userIsManager ? AUDIENCETYPES.MANAGER : currentAudienceType
            }
            audience={currentAudience}
          />
        )}
      </div>
      <div style={{ padding: "0px 12px" }}>
        <SupervisorModifyToggle />
      </div>
    </Right>
  );
};

export default AdminBox;
