/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Formik } from "formik";
import * as Yup from "yup";
import { CircularProgress, Paper } from "@material-ui/core";
import {
  Typography,
  Button,
  Alert,
  TextField,
} from "../../pages/private/styles";
import API from "../../axios/instances/API";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: 400px;
  max-height: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
`;

const Contact = ({ user }) => {
  const [msgSuccess, setMsgSuccess] = useState(false);
  const [msgError, setMsgError] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string("Enter your Name")
      .min(1, "Name must be 1 characters long")
      .required("Name is required"),
    email: Yup.string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    message: Yup.string()
      .min(8, "Message should be a minimum of 6 characters")
      .max(800, "Message can be at most 800 characters")
      .required("Message is required"),
  });

  const initialValues = {
    name: `${user.first_name} ${user.last_name}`,
    email: user.username,
    message: "",
  };

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    setMsgSuccess(false);
    setMsgError(false);
    try {
      await API.post("dashboard/contact/", {
        name: values.name,
        email: values.email,
        message: values.message,
      });
      setMsgSuccess(true);
      resetForm();
      setSubmitting(false);
    } catch (error) {
      setMsgError(true);
      setSubmitting(false);
      resetForm();
    }
  };

  return (
    <ModalWrapper>
      <Typography style={{ fontWeight: 600, fontSize: 16 }} mb={4}>
        Contact Us
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isSubmitting,
          dirty,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              type="text"
              name="name"
              label="Your Name"
              value={values.name}
              fullWidth
              disabled
              mt={2}
              autoComplete="new-password"
              variant="outlined"
              margin="none"
              size="small"
            />
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              fullWidth
              disabled
              mt={4}
              autoComplete="new-password"
              variant="outlined"
              margin="none"
              size="small"
            />
            <TextField
              multiline
              minRows={3}
              maxRows={3}
              mt={4}
              type="password"
              name="message"
              label="Message"
              value={values.message}
              fullWidth
              error={Boolean(touched.message && errors.message)}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.message && errors.message}
              autoComplete="new-password"
              variant="outlined"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              mt={4}
              fullWidth
              disabled={isSubmitting || !dirty}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            >
              {isSubmitting ? "Sending..." : "Send Message"}
            </Button>
          </form>
        )}
      </Formik>
      {msgSuccess && (
        <Alert severity="success" mt={2} onClose={() => setMsgSuccess(false)}>
          Message Sent! We will get back to you shortly.
        </Alert>
      )}
      {msgError && (
        <Alert severity="error" mt={2} onClose={() => setMsgError(false)}>
          We have encountered and error sending your message. Please try again
          later.
        </Alert>
      )}
    </ModalWrapper>
  );
};

export default Contact;

Contact.propTypes = {
  user: PropTypes.object.isRequired,
};
