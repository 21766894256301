/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import useAudienceToolStore from "../../../store/useAudienceToolStore";
import { AUDIENCETYPES } from "../../../storage/constants";
import { MapPin, Tag, Users, FilePlus } from "react-feather";

const Left = styled.div`
  height: 100%;
  width: 100%;
  padding: 4px;
  display: flex;
  flex-direction: column;
  background: #eeeeee;
  border-radius: 6px;
  flex-basis: 420px;
  max-width: 440px;
  min-width: 360px;
  height: 320px;
`;

const UpperDiv = styled.div`
  display: flex;
  align-items: center;
  justiy-content: flex-start;
  width: 100%;

  span {
    font-weight: 500;
  }
`;

const CustomAudienceButton = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  height: 58px;
  padding: 4px;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  min-width: 300px;
  min-height: 58px;
  background-color: white;
  padding: 0px 12px;
  border: ${(props) =>
    props.$checked ? "2px solid black" : "1px solid lightgray"};

  svg {
    color: black;

    margin-right: 6px;
  }

  span {
    white-space: nowrap;
    color: black;
  }

  &:hover {
    background-color: #eeeeee;

    span {
      color: black;
    }

    svg {
      color: black;
    }
  }

  &:disabled {
    svg {
      color: lightgray;
    }

    span {
      color: lightgray;
    }
  }
`;

const AudienceOptions = () => {
  const currentAudienceType = useAudienceToolStore(
    (state) => state.currentAudienceType,
  );
  const toggleLocationSelector = useAudienceToolStore(
    (state) => state.toggleLocationSelector,
  );
  const toggleRoleSelector = useAudienceToolStore(
    (state) => state.toggleRoleSelector,
  );
  const toggleConfirmAllTeamOpen = useAudienceToolStore(
    (state) => state.toggleConfirmAllTeamOpen,
  );

  const openTableInModal = useAudienceToolStore(
    (state) => state.openTableInModal,
  );

  return (
    <Left>
      <div
        style={{
          width: "100%",
          flex: 1,

          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: 8,
          borderRadius: 4,
          backgroundColor: "white",
          padding: 12,
        }}
      >
        <span
          style={{
            color: "darkgrey",

            fontWeight: "600",
            fontSize: 14,
            textTransform: "uppercase",
            padding: 2,
            color: "darkgray",
          }}
        >
          SELECT AUDIENCE TYPE
        </span>
        <CustomAudienceButton
          onClick={toggleConfirmAllTeamOpen}
          $checked={currentAudienceType === AUDIENCETYPES.SELECT_ALL}
        >
          <UpperDiv>
            <Users height={13} width={13} />
            <span style={{ fontSize: 12, fontWeight: "500" }}>
              All Team Members
            </span>
          </UpperDiv>

          <span
            style={{
              fontSize: 11,
              color: "darkgrey",
              fontWeight: "400",
              marginLeft: 18,
            }}
          >
            Send module to all team members, at all locations
          </span>
        </CustomAudienceButton>
        <CustomAudienceButton
          onClick={toggleLocationSelector}
          $checked={currentAudienceType === AUDIENCETYPES.LOCATIONS}
        >
          <UpperDiv>
            <MapPin height={13} width={13} />
            <span>By Location</span>
          </UpperDiv>
          <span
            style={{
              fontSize: 11,
              color: "darkgrey",
              fontWeight: "400",
              marginLeft: 18,
            }}
          >
            Send module to team members with specific locations
          </span>
        </CustomAudienceButton>

        <CustomAudienceButton
          onClick={toggleRoleSelector}
          $checked={currentAudienceType === AUDIENCETYPES.ROLES}
        >
          <UpperDiv>
            <Tag height={13} width={13} />
            <span>By Role</span>
          </UpperDiv>
          <span
            style={{
              fontSize: 11,
              color: "darkgrey",
              fontWeight: "400",
              marginLeft: 18,
            }}
          >
            Send module to team members with specific roles
          </span>
        </CustomAudienceButton>
        <CustomAudienceButton
          onClick={() => openTableInModal()}
          $checked={currentAudienceType === AUDIENCETYPES.OTHER}
        >
          <UpperDiv>
            <FilePlus height={13} width={13} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 8,
              }}
            >
              <span>Custom Audience Matrix</span>
            </div>
          </UpperDiv>
          <span
            style={{
              fontSize: 11,
              color: "darkgrey",
              fontWeight: "400",
              marginLeft: 18,
            }}
          >
            Build a highly customized audience with this visualizer
          </span>
        </CustomAudienceButton>
      </div>
    </Left>
  );
};

export default AudienceOptions;
