/* eslint-disable */
import { CircularProgress, Grid, Paper } from "@material-ui/core";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import API from "../../../axios/instances/API";
import ErrorAlertInvite from "../../../components/UI/ErrorAlertInvite";
import { getInvitesErrorMsgServer } from "../../../storage/helpers";
import EmailField from "../../public/Inputs/EmailField";
import FirstNameField from "../../public/Inputs/FirstNameField";
import LastNameField from "../../public/Inputs/LastNameField";
import { Button, Typography } from "../styles";
import PreInfoDialog from "../supervisors/PreInfoDialog";
import SelectAdminAccessButton from "./SelectAdminAccessButton";

const Wrapper = styled(Paper)`
  padding: 30px;
  min-width: min(600px, 100vw);
  max-width: 600px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const getIsFormDisabled = (errors, values, isSubmitting, selectedArray) => {
  if (isSubmitting) return true;

  if (!selectedArray || selectedArray?.length === 0) return true;

  if (Object.values(errors).length > 0) return true;

  if (!values.email || !values.firstName || !values.lastName) return true;

  return false;
};

const validationSchema = Yup.object({
  email: Yup.string("Enter email")
    .email("Enter a valid email")
    .required("Email is required"),
  firstName: Yup.string("Enter First Name").required("First Name is required"),
  lastName: Yup.string("Enter Last Name").required("Last Name is required"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
};

const InviteAdmin = ({ setSnackbarText, setAddAdminOpen, mutate }) => {
  const [dialogInfo, setDialogInfo] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);

  const [selectedArray, setSelectedArray] = useState([]);

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setErrorAlert(null);
    setSubmitting(true);

    const sendObj = {
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      access_data: selectedArray?.map((obj) => ({
        company_id: obj?.company_id,
        read_only: obj?.readOnly,
      })),
      confirm: false,
    };

    try {
      await API.post("dashboard/admin/invitations/", sendObj);
      setSnackbarText(
        `Admin Invitation sent to ${values.firstName} ${values.lastName} success`,
      );
      mutate();
      setSubmitting(false);
      return setAddAdminOpen(false);
    } catch (error) {
      setSubmitting(false);

      const errorObj = getInvitesErrorMsgServer(error);
      if (errorObj.status === 409) {
        errorObj.body = sendObj;
        return setDialogInfo(errorObj);
      }
      setErrorAlert(errorObj);
    }
  };

  const handleCloseDialog = () => {
    setDialogInfo(null);
    setAddAdminOpen(false);
  };

  const handleSubmitFromDialog = async (obj) => {
    const sendObj = { ...obj, confirm: true };
    await API.post("dashboard/admin/invitations/", sendObj);
    await mutate();
    setSnackbarText(
      `Invite sent to ${obj.first_name} ${obj.last_name} success`,
    );
  };

  return (
    <Wrapper>
      <PreInfoDialog
        open={Boolean(dialogInfo)}
        info={dialogInfo}
        handleSubmitFromDialog={handleSubmitFromDialog}
        handleClose={handleCloseDialog}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: -8,
        }}
      >
        <Typography variant="h3" align="left">
          Invite Admin
        </Typography>
      </div>
      <Typography align="left" variant="subtitle1" mb={2}>
        Fill out form to invite an Admin
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          isSubmitting,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <EmailField
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <FirstNameField
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <LastNameField
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectAdminAccessButton
                  selectedArray={selectedArray}
                  setSelectedArray={setSelectedArray}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  p={2}
                  mt={2}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  type="submit"
                  disabled={getIsFormDisabled(
                    errors,
                    values,
                    isSubmitting,
                    selectedArray,
                  )}
                  startIcon={isSubmitting && <CircularProgress size={20} />}
                >
                  {isSubmitting ? "Sending invite" : "Send Invitation"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      {errorAlert && (
        <>
          <div style={{ height: 12 }} />
          <ErrorAlertInvite
            errorMsg={errorAlert}
            handleClose={() => setErrorAlert(null)}
          />
        </>
      )}
    </Wrapper>
  );
};

export default InviteAdmin;

InviteAdmin.propTypes = {
  setSnackbarText: PropTypes.func.isRequired,
  setAddAdminOpen: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
};
