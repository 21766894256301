/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Tooltip, Modal, IconButton, Menu, MenuItem } from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";
import { Plus, Book, MapPin, Users, MoreVertical } from "react-feather";
import { Typography } from "../../styles";
import EditCompanyLogo from "../../../../components/Company/EditCompanyLogo";
import EditCompanyName from "../../../../components/Company/EditCompanyName";
import SnackBarWrapper from "../../../../components/SnackbarWrapper";
import { hasLogoImage } from "../../../../storage/helpers";
import InviteTeamMember from "../../teamMembers/InviteTeamMember";
import CreateNewLocation from "../../locations/CreateNewLocation";
import CreateQuizModal from "../../library/components/CreateQuizModal";

const CompanyBox = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  position: relative;
`;

const CompanyInfoBox = styled(CompanyBox)`
  padding: 10px;
  background-color: white;
`;

const Hometitle = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  color: black;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2px;
  height: 52px;
  margin-bottom: 2px;

  svg {
    height: 22px;
    width: 22px;
    color: black;
  }

  span {
    font-size: 15px;
    font-weight: 600;
    color: ${(props) => props.theme.palette.primary.dark};
  }

  p {
    font-size: 14px;
    font-weight: 900;P
    color: #337ab7;
  }
`;

const IconContainer = styled.div`
  border-radius: 6px;
  background-color: #eeeeee;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 10px;

  svg {
    color: black;
  }
`;

const AddCompanyImage = styled.div`
  height: 80px;
  width: 100px;
  border-radius: 6px;
  border: 2px dashed #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    border: 2px dashed ${(props) => props.theme.palette.info.light};

    svg {
      color: ${(props) => props.theme.palette.info.light};
    }
  }

  svg {
    height: 18px;
    width: 18px;
    color: ${(props) => props.theme.palette.grey[400]};
  }
`;

const LogoImage = styled.img`
  height: 80px;
  width: 80px;
  object-fit: contain;
  border-radius: 12px;
  filter: grayscale(75%);
`;

const CustomIconButton = styled(IconButton)`
  background-color: transparent;

  position: absolute;
  top: 8px;
  right: 8px;
  svg {
    color: #1a2a42;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-ttems: center;
  justify-content: space-between;

  span {
    font-size: 14px;
    font-weight: 400;
  }

  p {
    font-weight: 800;
    font-size: 16px;
    color: #337ab7;
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 0.75;
  overflow-x: hidden;
`;

const MarketplaceContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HoldMarketplaces = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
`;

const RightSide = ({ data, company, config }) => {
  const [editLogoModalOpen, setEditLogoModalOpen] = useState(false);
  const [editCompanyNameModalOpen, setEditCompanyNameModalOpen] =
    useState(false);
  const [addTeamMemberModalOpen, setAddTeamMemberModalOpen] = useState(false);
  const [createLocationModalOpen, setCreateLocationModalOpen] = useState(false);
  const [createModuleModalOpen, setCreateModuleModalOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const hasLogo = hasLogoImage(company?.logo);

  const handleCloseMenu = () => setAnchorEl(null);
  const handleOpenMenu = (e) => setAnchorEl(e.currentTarget);
  const menuOpen = Boolean(anchorEl);
  // const isLte = config.tier.lte;

  const { current_company: currentCompany } = config;

  return (
    <>
      <CompanyBox>
        <Tooltip title="Edit">
          <CustomIconButton aria-haspopup="true" onClick={handleOpenMenu}>
            <MoreVertical />
          </CustomIconButton>
        </Tooltip>
        <Menu
          open={menuOpen}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            style={{ fontSize: 12 }}
            onClick={() => {
              setEditLogoModalOpen(true);
              setAnchorEl(null);
            }}
          >
            Edit Company Logo
          </MenuItem>
          <MenuItem
            style={{ fontSize: 12 }}
            onClick={() => {
              setEditCompanyNameModalOpen(true);
              setAnchorEl(null);
            }}
          >
            Edit Company Name
          </MenuItem>
        </Menu>
        {hasLogo ? (
          <LogoImage alt="" src={company?.logo} />
        ) : (
          <Tooltip title="Add Company Logo" placement="top">
            <AddCompanyImage onClick={() => setEditLogoModalOpen(true)}>
              <Plus />
            </AddCompanyImage>
          </Tooltip>
        )}
        <Hometitle>{company?.name}</Hometitle>
        {/* <span
          style={{
            fontSize: 12,
            fontWeight: "600",
            color: "rgb(51, 122, 183)",
          }}
        >
          Account
          {isLte ? "Essentials" : "Pro"} Account
        </span> */}
        <MarketplaceContainer>
          {currentCompany?.available_marketplaces?.map((mp, i) => (
            <HoldMarketplaces key={mp.country_code}>
              <ReactCountryFlag
                countryCode={mp.country_code}
                style={{
                  fontSize: 16,
                  marginRight: 4,
                  marginLeft: i > 0 ? 6 : 2,
                }}
              />
              <span
                style={{ color: "darkgrey", fontSize: 12, fontWeight: "500" }}
              >
                {mp?.country}
              </span>
            </HoldMarketplaces>
          ))}
        </MarketplaceContainer>
      </CompanyBox>
      <CompanyInfoBox>
        <FlexDiv>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconContainer>
              <Users />
            </IconContainer>
            <span>Team Members</span>
          </div>
          <Tooltip title="Invite New Team Member" placement="left" arrow>
            <IconButton onClick={() => setAddTeamMemberModalOpen(true)}>
              <Plus />
            </IconButton>
          </Tooltip>
        </FlexDiv>
        <div style={{ padding: "2px 10px", width: "100%" }}>
          <Container>
            <span>Number of Team Members:</span>
            <p>{data?.company_data?.overall_members}</p>
          </Container>
          <Container>
            <span>Recently Joined:</span>
            <p>{data?.company_data?.new_members?.length}</p>
          </Container>
          <Container>
            <span>Pending:</span>
            <p>
              {config?.current_company?.pending_access_requests?.team_members ||
                0}
            </p>
          </Container>
        </div>
      </CompanyInfoBox>
      <CompanyInfoBox>
        <FlexDiv>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconContainer>
              <Book />
            </IconContainer>
            <span>Library</span>
          </div>
          {/* {isLte ? (
            <div />
          ) : ( */}
          <Tooltip title="Create New Module" placement="left" arrow>
            <IconButton onClick={() => setCreateModuleModalOpen(true)}>
              <Plus />
            </IconButton>
          </Tooltip>
          {/* )} */}
        </FlexDiv>
        <div style={{ padding: "2px 10px", width: "100%" }}>
          <Container>
            <span>Active Modules:</span>
            <p>{data?.company_data?.live_quizzes}</p>
          </Container>
          <Container>
            <span>Suggested Modules:</span>
            <p>{data?.company_data?.suggested_quizzes}</p>
          </Container>
        </div>
      </CompanyInfoBox>

      <CompanyInfoBox>
        <FlexDiv>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconContainer>
              <MapPin />
            </IconContainer>
            <span>Locations</span>
          </div>
          <Tooltip title="Create New Location" placement="left" arrow>
            <IconButton onClick={() => setCreateLocationModalOpen(true)}>
              <Plus />
            </IconButton>
          </Tooltip>
        </FlexDiv>
        <div style={{ padding: "2px 10px", width: "100%" }}>
          <Container>
            <span>Number of Locations:</span>
            <p>{data?.company_data?.locations}</p>
          </Container>
        </div>
      </CompanyInfoBox>

      <Modal
        open={editLogoModalOpen}
        onClose={() => setEditLogoModalOpen(false)}
        aria-labelledby="Change companys logo"
        aria-describedby="modal to change company logo"
      >
        <div>
          <EditCompanyLogo
            handleClose={() => setEditLogoModalOpen(false)}
            setSnackbarText={setSnackbarText}
          />
        </div>
      </Modal>
      <Modal
        open={editCompanyNameModalOpen}
        onClose={() => setEditCompanyNameModalOpen(false)}
        aria-labelledby="Change company name"
        aria-describedby="modal to edit company name"
      >
        <div>
          <EditCompanyName
            handleClose={() => setEditCompanyNameModalOpen(false)}
            setSnackbarText={setSnackbarText}
          />
        </div>
      </Modal>
      <Modal
        open={addTeamMemberModalOpen}
        onClose={() => setAddTeamMemberModalOpen(false)}
        aria-describedby="Modal to invite team member"
        aria-labelledby="Invite new team member"
      >
        <div>
          <InviteTeamMember
            setSnackbarText={setSnackbarText}
            setInviteMemberModal={setAddTeamMemberModalOpen}
          />
        </div>
      </Modal>
      <Modal
        open={createLocationModalOpen}
        onClose={() => setCreateLocationModalOpen(false)}
        aria-describedby="Modal to create a new location"
        aria-labelledby="Create a new location"
      >
        <div>
          <CreateNewLocation
            setSnackbarText={setSnackbarText}
            setCreateModalOpen={setCreateLocationModalOpen}
          />
        </div>
      </Modal>
      <Modal
        open={createModuleModalOpen}
        onClose={() => setCreateModuleModalOpen(false)}
        aria-describedby="Modal to create a new location"
        aria-labelledby="Create a new location"
      >
        <div>
          <CreateQuizModal setModalOpen={setCreateModuleModalOpen} />
        </div>
      </Modal>
      {/* <UserBox /> */}
      <SnackBarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default RightSide;

RightSide.propTypes = {
  data: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};
