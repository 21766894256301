/* eslint-disable */

import React, { useState } from "react";
import styled from "styled-components/macro";
import { Paper, Button, Typography, CircularProgress } from "@material-ui/core";
import useQuizReview from "../../../../swr/hooks/Library/useQuizReview";
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import API from "../../../../axios/instances/API";
import { apiFormatDate, getSrcTwo } from "../../../../storage/helpers";
import { addDays } from "date-fns";
import { Alert } from "@material-ui/lab";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: calc(min(420px, 96vw));
  max-height: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
`;

const SelectedIndicator = styled.div`
  height: 13px;
  width: 13px;
  min-width: 13px;
  min-height: 13px;
  background: ${(props) => (props.$isSelected ? "#337AB7" : "lightgray")};
  margin-right: 8px;
  border-radius: 4px;
`;

const getErrorObj = (error) => {
  return {
    title: error?.response?.data?.error?.details || "Error",
    body:
      error?.response?.data?.error?.context?.start_date ||
      "There was an error updating the start date.",
  };
};

const EditStartDateModal = ({ id, setSnackbarText, handleClose }) => {
  const { details: data, isLoading } = useQuizReview(id);

  const [selectedDate, setSelectedDate] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);

  const handleUpdateStartDate = async () => {
    try {
      setUpdating(true);
      const sendObj = {
        id,
        due_date: data?.dueDate || null,
        start_date: apiFormatDate(selectedDate),
        name: data?.name,
        description: data?.description,
        estimated_time: data?.estimatedTime,
        language: data?.language,
        disclaimer: data?.disclaimer,
        disclaimer_text: data?.disclaimerText,
        display_answers: data?.displayAnswers,
      };

      await API.put("dashboard/quiz/details/", sendObj);
      setSnackbarText("Module start date updated success");
      return handleClose();
    } catch (error) {
      const errorObj = getErrorObj(error);
      setError(errorObj);
      setUpdating(false);
    }
  };

  if (isLoading) {
    return (
      <ModalWrapper>
        <div
          style={{
            width: "100%",
            height: "100%",
            minHeight: 160,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ fontSize: 12, color: "lightgray" }}>loading...</span>
        </div>
      </ModalWrapper>
    );
  }

  const minimumStartDate = addDays(new Date(data.startDate), 2);

  return (
    <ModalWrapper>
      <div
        style={{
          width: "100%",
          minHeight: 140,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            height: 48,
            width: "100%",
            marginBottom: 24,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            borderRadius: 2,
            width: "100%",
          }}
        >
          <img
            src={getSrcTwo(data.image)}
            style={{
              height: 46,
              width: 46,
              minHeight: 46,
              minWidth: 46,
              background: "white",
              border: "1px solid #eeeeee",
              borderRadius: 8,
              objectFit: "contain",
            }}
          />
          <div style={{ marginLeft: 6 }}>
            <Typography style={{ fontSize: 14, fontWeight: "600" }}>
              {data.name}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              {data?.marketPlaceParent?.company?.name}
            </Typography>
          </div>
        </div>
        <span style={{ fontWeight: "500", fontSize: 11, marginBottom: 4 }}>
          Select New Start Date
        </span>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            disableToolbar
            disablePast
            emptyLabel="Start Date"
            fullWidth
            // minDate={minimumStartDate}
            autoOk
            inputVariant="outlined"
            size="small"
            variant="inline"
            format="yyyy-MM-dd"
            value={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            InputProps={{
              startAdornment: (
                <SelectedIndicator $isSelected={Boolean(selectedDate)} />
              ),
            }}
          />
        </MuiPickersUtilsProvider>
        <div style={{ marginBottom: 8, marginTop: 8, padding: 2 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <span style={{ fontSize: 11, color: "darkgrey" }}>
              Module start date:
            </span>
            <span
              style={{
                fontSize: 11,
                color: "black",
                fontWeight: "500",
                marginLeft: 4,
              }}
            >
              {!data.startDate
                ? "None"
                : addDays(new Date(data.startDate), 1).toDateString()}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: 6,
            }}
          >
            <span style={{ fontSize: 11, color: "darkgrey" }}>
              Updated Module Start Date:
            </span>
            <span
              style={{
                fontSize: 11,
                color: "#337ab7",
                fontWeight: "500",
                marginLeft: 4,
              }}
            >
              {!selectedDate ? "---" : selectedDate.toDateString()}
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",

            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={!selectedDate || updating}
            onClick={handleUpdateStartDate}
            startIcon={updating ? <CircularProgress size={16} /> : null}
          >
            Update
          </Button>
        </div>
        {Boolean(error) && (
          <div style={{ width: "100%" }}>
            <div style={{ height: 8 }} />
            <Alert
              onClose={() => setError(null)}
              fullWidth
              severity="error"
              style={{
                width: "100%",
                minWidth: "100%",
                border: "1px solid firebrick",
              }}
            >
              <p style={{ fontWeight: "600" }}>{error?.title || "---"}</p>
              <p style={{ fontSize: 12 }}>{error?.body}</p>
              <div style={{ width: "100%" }} />
            </Alert>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};

export default EditStartDateModal;
