/* eslint-disable */
/* redeploy */
import React from "react";
import { Switch, Route } from "react-router-dom";
import NoMatch from "./pages/Nomatch";
import routes from "./routes";
import authInterceptor from "./axios/interceptors/authInterceptor";
import responseInterceptor from "./axios/interceptors/responseInterceptor";
import RouteWrapper from "./components/utility/RouteWrapper";
import * as amplitude from "@amplitude/analytics-browser";

authInterceptor();
responseInterceptor();

// amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, {});

function App() {
  return (
    <div className="App">
      <Switch>
        {routes.map((route) => (
          <RouteWrapper {...route} key={route.path} />
        ))}
        <Route component={NoMatch} />
      </Switch>
    </div>
  );
}

export default App;
