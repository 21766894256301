/* eslint-disable */
// Reuseable Data Mapping Functions In Here //
import { ACCESS_LEVELS } from "../../storage/constants";

export const cleanAccessStateForApi = (accessState) => {
  const cleaned = accessState
    .filter((obj) =>
      [ACCESS_LEVELS.READ_ONLY, ACCESS_LEVELS.WRITE].includes(
        obj.admins_access,
      ),
    )
    .filter((obj) => !obj?.disabled)
    .map((obj) => ({
      company_id: obj?.company_id,
      read_only: obj?.admins_access === ACCESS_LEVELS.READ_ONLY,
    }));

  return cleaned;
};

export const getStatefulAccess = (access, fromConfigObj = false) => {
  const cleanState = access?.map((obj) => ({
    company_id: obj?.company_id,
    admins_access: fromConfigObj ? ACCESS_LEVELS.NONE : obj?.admins_access,
    disabled:
      !obj?.requesters_access || obj?.requesters_access !== ACCESS_LEVELS.WRITE,
    company_name: obj?.company_name,
    company_logo: obj?.company_logo,
    hasActiveTeamMember:
      obj?.admin_has_active_team_members === undefined
        ? null
        : obj?.admin_has_active_team_members,
  }));
  return cleanState;
};
