/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Button, IconButton, Modal, Paper, Switch } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { PlusCircle, X } from "react-feather";
import styled from "styled-components/macro";
import { GlobalState } from "../../../store/GlobalState";
import { getStatefulAccess } from "../../../components/AdminAccess/utils";

const Wrapper = styled.div`
  width: 100%;
  background: white;
  border-radius: 4px;
`;

const ModalWrapper = styled(Paper)`
  padding: 14px;
  width: 360px;
  height: 480px;
  max-height: 90vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const Item = styled.div`
  height: 40px;
  min-height: 40px;
  width: 100%;
  border-bottom: 1px dashed lightgray;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
  gap: 4px;
  margin-bottom: 4px;
`;

const ItemContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow-y: scroll;
  flex-direction: column;
  gap: 4px;
  padding: 4px 0px;
`;

const ReadOnlySwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 1px 4px;
  margin-right: 12px;
`;

const CompanyImg = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid #eeeeee;
`;

const SelectedArrayContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 4px;
  max-height: 300px;
  overflow-y: scroll;
`;

const SelectedArrayItem = styled.div`
  width: 100%;
  border-bottom: 1px dashed lightgray;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  gap: 4px;
`;

const SelectAdminAccessButton = ({ selectedArray, setSelectedArray }) => {
  const [open, setOpen] = useState(false);
  const { state } = useContext(GlobalState);
  const { config } = state;

  const requestorsOptions = getStatefulAccess(
    config?.user?.requesters_access_in_current_environment,
    true,
  );

  useEffect(() => {
    const currentCompanyId = config?.current_company?.id;

    const initialCompany = requestorsOptions.find(
      (obj) => obj?.company_id === currentCompanyId,
    );

    setSelectedArray([
      { ...initialCompany, removeable: false, readOnly: false },
    ]);
  }, []);

  const selectedIds = selectedArray?.map((obj) => obj?.company_id);

  const filteredOptions = requestorsOptions?.filter(
    (opt) => !opt.disabled && !selectedIds.includes(opt?.company_id),
  );

  const toggleReadOnly = (id) => {
    const newSelectedArray = selectedArray.map((obj) => ({
      ...obj,
      readOnly: id === obj?.company_id ? !obj.readOnly : obj?.readOnly,
    }));

    setSelectedArray(newSelectedArray);
  };

  const handleRemove = (id) => {
    const newSelectedArray = selectedArray.filter(
      (obj) => obj?.company_id !== id,
    );
    setSelectedArray(newSelectedArray);
  };

  const handleAdd = (obj) => {
    setSelectedArray((p) => [
      ...p,
      { ...obj, readOnly: false, removeable: true },
    ]);
  };

  return (
    <>
      <Wrapper>
        <div
          style={{
            width: "100%",
            height: 38,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            padding: "4px 8px",
            borderRadius: 4,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          <span style={{ color: "darkgrey", fontSize: 13 }}>
            Invite for admin access at the following companies.
          </span>
          <div />
          <Button
            startIcon={<PlusCircle height={14} width={14} color="#337ab7" />}
            onClick={() => setOpen(true)}
            size="small"
            variant="outlined"
            style={{ background: "white" }}
          >
            Add
          </Button>
        </div>
        {selectedArray?.length === 0 ? (
          <div
            style={{
              height: 38,
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ color: "darkgrey", fontWeight: 400, fontSize: 12 }}>
              -- None Selected --{" "}
            </span>
          </div>
        ) : (
          <SelectedArrayContainer>
            {selectedArray?.map((obj) => (
              <SelectedArrayItem key={obj?.company_id}>
                <CompanyImg src={obj?.company_logo} />
                <span style={{ fontWeight: 500, fontSize: 12 }}>
                  {obj?.company_name}
                </span>
                <div style={{ marginLeft: "auto" }} />
                <ReadOnlySwitchWrapper>
                  <span style={{ fontSize: 11, color: "slategrey" }}>
                    Read Only Admin?
                  </span>
                  <Switch
                    checked={obj?.readOnly}
                    size="small"
                    onClick={() => toggleReadOnly(obj?.company_id)}
                  />
                </ReadOnlySwitchWrapper>

                <IconButton
                  onClick={() => handleRemove(obj?.company_id)}
                  disabled={!obj?.removeable}
                  style={{
                    marginRight: 4,
                    background: "#eeeeee",
                    borderRadius: 4,
                  }}
                  size="small"
                >
                  <X
                    height={16}
                    width={16}
                    color={obj?.removeable ? "slategrey" : "lightgray"}
                  />
                </IconButton>
              </SelectedArrayItem>
            ))}
          </SelectedArrayContainer>
        )}
      </Wrapper>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <ModalWrapper>
            <span style={{ fontSize: 15, fontWeight: 600 }}>
              Available Companies
            </span>

            <p
              style={{
                marginTop: -2,
                fontSize: 12,
                fontWeight: 400,
                color: "darkgrey",
              }}
            >
              Grant admin access at additional companies.
            </p>

            <ItemContainer>
              {filteredOptions?.map((obj) => (
                <Item key={obj?.company_id}>
                  <CompanyImg src={obj?.company_logo} />
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      maxWidth: 210,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {obj?.company_name}
                  </span>
                  <div style={{ marginLeft: "auto" }} />
                  <Button
                    startIcon={
                      <PlusCircle height={11} width={11} color="#337ab7" />
                    }
                    onClick={() => handleAdd(obj)}
                    size="small"
                    variant="outlined"
                    style={{
                      borderRadius: 6,
                      padding: 0,
                      height: 28,
                    }}
                  >
                    Add
                  </Button>
                </Item>
              ))}
            </ItemContainer>
          </ModalWrapper>
        </div>
      </Modal>
    </>
  );
};

export default SelectAdminAccessButton;
