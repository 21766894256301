/* eslint-disable */
import React from "react";
import { Switch } from "@material-ui/core";
import { USER_TYPES } from "../../../storage/constants";
import styled from "styled-components/macro";
import useAudienceToolStore from "../../../store/useAudienceToolStore";
import { ThumbsDown, ThumbsUp } from "react-feather";

const Wrapper = styled.div`
  width: 100%;
  height: 48px;
  margin-right: auto;
  align-self: flex-start;
  border-radius: 6px;
  border: 1px solid
    ${(props) =>
      props.$canModify === "y"
        ? "green"
        : props.$canModify === "n"
        ? "firebrick"
        : "lightgray"};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-width: 300px;
`;

const OuterWrapper = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`;

const SupervisorModifyToggle = () => {
  const canManagersModify = useAudienceToolStore(
    (state) => state.canManagersModify,
  );
  const handleToggleAudienceLock = useAudienceToolStore(
    (state) => state.handleToggleAudienceLock,
  );
  const userType = useAudienceToolStore((state) => state.userType);
  const initialLoad = useAudienceToolStore((state) => state.initialLoad);
  const isTemplate = useAudienceToolStore((state) => state.isTemplate);

  if (isTemplate || initialLoad) return null;

  if (
    [
      USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY,
      USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY,
    ].includes(userType)
  ) {
    return (
      <OuterWrapper>
        <Wrapper $canModify={canManagersModify ? "y" : "n"}>
          <div
            style={{
              height: 46,
              padding: "2px 8px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              borderRadius: 4,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 6,
              }}
            >
              <span style={{ fontSize: 12, fontWeight: "500", color: "black" }}>
                {canManagersModify
                  ? "Supervisors can modify"
                  : "Supervisors can not modify"}
              </span>
              {canManagersModify ? (
                <ThumbsUp height={12} width={12} color="mediumseagreen" />
              ) : (
                <ThumbsDown height={12} width={12} color="firebrick" />
              )}
            </div>
            <span style={{ fontSize: 10, color: "darkgrey" }}>
              {canManagersModify
                ? "You can modify which of your team members receive this module"
                : "You do not have permission to modify this audience"}
            </span>
          </div>
        </Wrapper>
      </OuterWrapper>
    );
  }

  return (
    <OuterWrapper>
      <Wrapper style={{ background: "#eeeeee44" }}>
        <div
          style={{
            height: 46,
            padding: "2px 8px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            borderRadius: 4,
          }}
        >
          <span
            style={{
              fontSize: 11,
              fontWeight: "500",
              color: "black",
            }}
          >
            Allow supervisors to change this module’s audience?
          </span>
        </div>
        <div
          style={{
            background: "#eeeeee50",
            flexBasis: 60,
            height: 46,

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {initialLoad ? (
            <div />
          ) : (
            <Switch
              size="small"
              color="secondary"
              checked={canManagersModify}
              onChange={handleToggleAudienceLock}
            />
          )}
        </div>
      </Wrapper>
    </OuterWrapper>
  );
};

export default SupervisorModifyToggle;
