/* eslint-disable */
import React, { useState } from "react";
import { Modal, useMediaQuery } from "@material-ui/core";
import { Copy, List, Settings } from "react-feather";
import API from "../../../axios/instances/API";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import ActionButtonForTables from "../../../components/Table/ActionButtonForTables";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";
import CustomSearchBarForTables from "../../../components/Table/CustomSearchBarForTables";
import TableTitle from "../../../components/Table/TableTitle";
import { CustomDatatableV2 } from "../styles";
// import DeleteAdminModal from "./DeleteAdminModal";
import ModifyAdminPermissionsModal from "./ModifyAdminPermissionsModal";

const HINT_STRING =
  "This represents the total number of brands followed within your organization via referral from this admin. Ex: If you have 2 brands and a user scans your teams referral code, the user count would go up by 1 and the brands followed would go up by 2 because they followed 2 brands.";
const HINT_STIRNG_2 =
  "This represents the total number of people this user has successfully referred to view your content on the app.";

const getAdminStatus = (user) => {
  if (user.read_only) return "Read-Only Access";
  return "Write Access";
};

const AdminTable = ({
  readOnly,
  isRetail,
  mutate,
  admins,
  isLoading,
  userId,
}) => {
  const [snackbarText, setSnackbarText] = useState("");
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const [permissionId, setPermissionId] = useState(null);

  if (isLoading) return null;

  const options = {
    filterType: "textField",
    filter: false,
    print: true,
    viewColumns: false,
    elevation: 1,
    selectableRows: "none",
    rowsPerPage: 50,
    jumpToPage: true,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    responsive: "standard",
    downloadOptions: {
      filename: "admin-list.csv",
    },
  };

  options.customSearchRender = (searchText, onSearch, onHide) => (
    <CustomSearchBarForTables
      searchText={searchText}
      onSearch={onSearch}
      onHide={onHide}
    />
  );

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  /// If company is in org, only org access can edit others, else anyone without read only can
  // You cannot edit your own accesss

  const handleCopyToClipboard = async (id) => {
    const { data } = await API.get(`/dashboard/admin/details/?id=${id}`);
    navigator.clipboard.writeText(data.referral);
    return setSnackbarText("Referral code copied to clipboard info");
  };

  const tableData = admins.admins.map((user) => ({
    id: user.user_id,
    name: `${user.first_name} ${user.last_name}`,
    email: user.username,
    invitesSent: user.referral_data.unique_users_followed,
    overallFollowings: user.referral_data?.overall_followings || 0,
    privilege: getAdminStatus(user),
  }));

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "privilege",
      label: "Permission Level",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (val, tableMeta) => {
          return (
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <span>{val}</span>
              <ActionButtonForTables
                title="Manage Permissions"
                Icon={Settings}
                disabled={userId == tableMeta?.rowData[0] || readOnly}
                handleClick={() => {
                  setPermissionId(tableMeta?.rowData[0]);
                }}
              />
            </div>
          );
        },
      },
    },
    {
      name: "invitesSent",
      label: "Users Referred",
      options: {
        filter: false,
        sort: true,
        hint: HINT_STIRNG_2,
        display: !isRetail,
      },
    },
    {
      name: "overallFollowings",
      label: "Brand follows generated",
      options: {
        filter: false,
        hint: HINT_STRING,
        sort: true,
        display: !isRetail,
      },
    },

    {
      name: "copy",
      label: "Referral Code",
      options: {
        sort: false,
        filter: false,
        display: !isRetail,
        download: false,
        customBodyRender: (_, tableMeta) => (
          <ActionButtonForTables
            Icon={Copy}
            title="Copy referral code"
            handleClick={() => handleCopyToClipboard(tableMeta.rowData[0])}
          >
            <Copy />
          </ActionButtonForTables>
        ),
      },
    },
    // {
    //   name: "delete",
    //   label: "Delete",
    //   options: {
    //     sort: false,
    //     display: false,
    //     filter: false,
    //     empty: true,
    //     download: false,
    //     customBodyRender: (_, tableMeta) => {
    //       const idOfAdmin = tableMeta.rowData[0];
    //       const isUserThisAdmin = idOfAdmin === userId;
    //       const isButtonDisabled = readOnly || isUserThisAdmin;
    //       return (
    //         <DeleteButtonForTables
    //           disabled={isButtonDisabled}
    //           handleClick={() => {
    //             const { rowData } = tableMeta;
    //             setDeleteInfo({
    //               id: rowData[0],
    //               name: rowData[1],
    //               description: "Are you sure you want to delete admin",
    //               header: "Delete This Admin",
    //               snack: `Deleted Admin ${rowData[1]} warning`,
    //             });
    //           }}
    //         />
    //       );
    //     },
    //   },
    // },
  ];

  return (
    <>
      <CustomDatatableV2
        title={<TableTitle title="Admin List" Icon={List} />}
        options={options}
        columns={columns}
        data={tableData}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      {/* <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Delete a Admin User"
        aria-describedby="Delete a Pending Admin User"
      >
        <div>
          <DeleteAdminModal
            deleteInfo={deleteInfo}
            setSnackbarText={setSnackbarText}
            setDeleteInfo={setDeleteInfo}
            mutate={mutate}
          />
        </div>
      </Modal> */}
      <Modal
        open={Boolean(permissionId)}
        onClose={() => setPermissionId(null)}
        aria-labelledby="Change an Admins access"
        aria-describedby="Modal to change an admins access"
      >
        <div>
          <ModifyAdminPermissionsModal
            permissionId={permissionId}
            setPermissionId={setPermissionId}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
          />
        </div>
      </Modal>

      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default AdminTable;
