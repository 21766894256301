/* eslint-disable */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Paper, CircularProgress } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import Cropper from "react-easy-crop";
import styled from "styled-components/macro";
import API from "../../axios/instances/API";
import { GlobalState } from "../../store/GlobalState";
import { Button, Typography, Alert } from "../../pages/private/styles";
import { createImage, getConfig } from "../../storage/helpers";
import ACTIONS from "../../store/actions";
import { mutate } from "swr";
import { ArrowLeft, ChevronRight } from "react-feather";

const Wrapper = styled(Paper)`
  padding: 20px;
  min-height: 280px;
  width: 500px;
  border: 2px solid black;
  text-align: left;
  max-width: 560px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledCropper = styled(Cropper)`
  .reactEasyCrop_CropArea .reactEasyCrop_CropAreaGrid {
    color: #eeeeee73;
  }
`;

const CropWrapper = styled.div`
  padding: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HoldCrop = styled.div`
  height: 250px;
  width: 260px;
  background: white;
  position: relative;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 8px;
  align-items: center;
  justify-content: space-between;
`;

const AfterCropContainer = styled.div`
  width: 100%;
  height: 250px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  img {
    height: 176px;
    width: 176px;
  }
`;

const Stepper = styled.div`
  height: 26px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

const StepItem = styled.div`
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: ${(props) => (props.$active ? "slategrey" : "lightgray")};
    font-weight: 500;
  }

  svg {
    color: ${(props) => (props.$active ? "slategrey" : "lightgray")};
    height: 12px;
    width: 12px;
  }
`;

const BackButton = styled(Button)`
  heigth: 38px;
  width: 38px;
  max-width: 38px;
  max-height: 38px;
  min-width: 38px;
  min-height: 38px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee;
`;

const EditCompanyLogo = ({ setSnackbarText, handleClose }) => {
  const { state, dispatch } = useContext(GlobalState);
  const { config } = state;
  const { read_only: readOnly } = config?.user;
  const [isUploading, setIsUploading] = useState(false);
  const [uploadKey, setUploadKey] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [testFile, setTestFile] = useState("");
  const [complete, setComplete] = useState(null);
  const [backup, setBackup] = useState(null);
  const [afterCrop, setAfterCrop] = useState(null);
  const [step, setStep] = useState(0);

  const handleUpload = async (blob) => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append("logo", blob);
      setIsUploading(true);
      await API.post("dashboard/company/logo/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const data = await getConfig();
      dispatch({ type: ACTIONS.UPDATE_CONFIG, payload: data });
      setUploadKey((p) => !p);
      setAfterCrop(null);
      mutate("dashboard/company/details/");
      setIsUploading(false);
      setSnackbarText("Successfully uploaded new company image success");
      setStep(0);
      return handleClose();
    } catch (error) {
      setIsUploading(false);
      return setSnackbarText("Failed to upload new company image error");
    }
  };

  const getCroppedImg = async () => {
    const image = await createImage(testFile);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = 500;
    canvas.height = 500;

    ctx.drawImage(
      image,
      complete.x,
      complete.y,
      complete.width,
      complete.height,
      0,
      0,
      canvas.width,
      canvas.height,
    );

    canvas.toBlob(
      (blob) => {
        const newUrl = URL.createObjectURL(blob, "");
        setAfterCrop(newUrl);
        setTestFile(blob);
      },
      "image/png",
      1,
    );
    setStep(2);
  };

  return (
    <>
      <Wrapper>
        <Typography style={{ fontSize: 16, fontWeight: "600" }}>
          Upload Company Logo
        </Typography>
        <Stepper>
          <StepItem $active={step === 0}>
            <span>Drop</span>
            <ChevronRight />
          </StepItem>
          <StepItem $active={step === 1}>
            <span>Crop</span>
            <ChevronRight />
          </StepItem>
          <StepItem $active={step === 2}>
            <span>Upload</span>
          </StepItem>
        </Stepper>

        {!testFile && !isUploading && (
          <>
            <div style={{ height: 8 }} />
            <DropzoneArea
              acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
              showFileNamesInPreview
              showFileNames
              filesLimit={1}
              disabled={readOnly}
              showAlerts={false}
              onChange={(file) => {
                if (!file) return;
                if (file.length === 0) return;
                const test = URL.createObjectURL(file[0]);
                setBackup(test);
                setTestFile(test);
                setStep(1);
              }}
              dropzoneText="Drop logo here (or click)"
              key={uploadKey}
            />
          </>
        )}
        {testFile.length > 0 && (
          <>
            <CropWrapper>
              <HoldCrop>
                <StyledCropper
                  image={testFile}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  minZoom={0.4}
                  restrictPosition={false}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={(_, b) => setComplete(b)}
                />
              </HoldCrop>
            </CropWrapper>

            <ButtonContainer>
              <BackButton
                onClick={() => {
                  setTestFile("");
                  setBackup(null);
                  setStep(0);
                }}
              >
                <ArrowLeft />
              </BackButton>
              <Button
                onClick={getCroppedImg}
                variant="contained"
                color="secondary"
              >
                Use Image
              </Button>
            </ButtonContainer>
          </>
        )}
        {(afterCrop || isUploading) && (
          <>
            <CropWrapper>
              <AfterCropContainer>
                <img src={afterCrop} alt="" style={{ borderRadius: 6 }} />
              </AfterCropContainer>
            </CropWrapper>
            <ButtonContainer>
              <BackButton
                onClick={() => {
                  setTestFile(backup);
                  setAfterCrop(null);
                  setStep(1);
                }}
              >
                <ArrowLeft />
              </BackButton>
              <Button
                variant="contained"
                disabled={isUploading}
                color="secondary"
                onClick={() => {
                  handleUpload(testFile);
                  setTestFile("");
                }}
                startIcon={
                  isUploading ? (
                    <CircularProgress color="black" size={14} />
                  ) : null
                }
              >
                {isUploading ? "Uploading Logo" : "Upload Image"}
              </Button>
            </ButtonContainer>
          </>
        )}
        {!testFile && !isUploading && (
          <Alert severity="info" style={{ width: "100%" }} mt={2}>
            <p style={{ fontWeight: "600" }}>Upload Info</p>
            <ul style={{ marginLeft: 8 }}>
              <li>Photo must be in .jpeg, .jpg or .png format</li>
              <li>Photos will be cropped to 1/1 ratio</li>
            </ul>
          </Alert>
        )}
      </Wrapper>
    </>
  );
};

export default EditCompanyLogo;

EditCompanyLogo.propTypes = {
  setSnackbarText: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
