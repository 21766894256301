/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import { Paper, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const Wrapper = styled(Paper)`
  padding: 20px;
  min-height: 200px;
  width: 600px;
  max-height: 90vh;
  background: white;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid
    ${(props) => (props.$didSucceed ? "mediumseagreen" : "tomato")};
  flex-direction: column;
`;

const SecondaryWrapper = styled.div`
  border: 1px solid lightgray;
  background: #eeeeee60;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  overflow-y: scroll;
  max-height: 260px;
`;

const ShowResults = ({
  handleExit = () => {},
  handleUploadMore = () => {},
  results,
}) => {
  const didSucceed = results?.result !== "Failed";

  return (
    <Wrapper $didSucceed={didSucceed}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // border: "1px solid red",
          width: "100%",
        }}
      >
        <p style={{ fontSize: 16, fontWeight: "600" }}>Csv Upload Results</p>
        {didSucceed ? (
          <div
            style={{
              display: "flex",
              padding: "2px 8px",
              borderRadius: 3,
              background: "mediumseagreen",
            }}
          >
            <span
              style={{
                color: "white",
                textTransform: "uppercase",
                fontSize: 11,
                fontWeight: 800,
              }}
            >
              Success
            </span>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              padding: "2px 8px",
              borderRadius: 3,
              background: "tomato",
            }}
          >
            <span
              style={{
                color: "white",
                textTransform: "uppercase",
                fontSize: 11,
                fontWeight: 800,
              }}
            >
              Failed
            </span>
          </div>
        )}
      </div>

      <div style={{ height: 12 }} />
      {didSucceed ? (
        <Alert severity="success" style={{ width: "100%" }} variant="filled">
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            {results?.numberOfInvitesSent} Invites successfully sent
          </p>
          <p style={{ fontSize: 13, fontWeight: 400 }}>
            These invites can now be found in the outstanding team member table.
          </p>
        </Alert>
      ) : (
        <>
          <Alert severity="error" variant="filled" style={{ width: "100%" }}>
            <p style={{ fontSize: 13, fontWeight: 600 }}>{results?.title}</p>
            <p style={{ fontSize: 12, fontWeight: 500 }}>{results?.body}</p>
          </Alert>
          {Boolean(results?.rowErrors) && (
            <>
              <div style={{ height: 12 }} />
              <SecondaryWrapper>
                <p
                  style={{
                    fontWeight: 500,
                    color: "firebrick",
                    marginBottom: 4,
                  }}
                >
                  Errors found in these rows
                </p>
                {Object.values(results?.rowErrors).map((error) => (
                  <div
                    style={{
                      marginBottom: 2,
                      borderBottom: "1px solid #eeeeee",
                      padding: 4,
                    }}
                    key={error?.row?.row}
                  >
                    <p style={{ fontSize: 11, fontWeight: 500 }}>
                      (Row {error?.row?.row + 1}){"   "}
                      {error?.row?.email || "No email provided"}
                    </p>
                    <pre
                      style={{
                        fontSize: 11,
                        color: "darkgrey",
                        fontFamily: "inherit",
                      }}
                    >
                      {error?.msg?.join(" \n")}
                    </pre>
                  </div>
                ))}
              </SecondaryWrapper>
            </>
          )}
          {Boolean(results?.generalErrors) && (
            <>
              <div style={{ height: 12 }} />
              <SecondaryWrapper>
                <span
                  style={{
                    fontWeight: 500,
                    color: "firebrick",
                    marginBottom: 4,
                  }}
                >
                  General Errors
                </span>
                {Object.values(results?.generalErrors).flatMap((error) => (
                  <div key={error}>
                    <span style={{ fontSize: 12 }}>{error}</span>
                  </div>
                ))}
              </SecondaryWrapper>
            </>
          )}
        </>
      )}

      <div
        style={{
          marginTop: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 8,
          width: "100%",
          marginTop: 12,
        }}
      >
        {didSucceed && <Button onClick={handleExit}>Finished</Button>}
        <Button
          onClick={handleUploadMore}
          variant="outlined"
          // style={{ background: "black", color: "white" }}
        >
          {didSucceed ? "Upload More" : "Exit"}
        </Button>
      </div>
    </Wrapper>
  );
};

export default ShowResults;
