/* eslint-disable */
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  Button,
  Collapse,
  Modal,
  Paper,
  TextField,
  Switch,
  Tooltip,
} from "@material-ui/core";
import API from "../../axios/instances/API";
import { Autocomplete, Alert } from "@material-ui/lab";
import { useDebounce } from "use-debounce";
import { clearSWRKeys } from "../../storage/helpers";
import { useHistory } from "react-router-dom";
import ACTIONS from "../../store/actions";
import { Box } from "react-feather";

const ModalWrapper = styled(Paper)`
  padding: 14px;
  width: calc(min(500px, 96vw));
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const SuperDisguiseButton = styled(Button)`
  height: 32px;
  width: 32px;
  max-width: 32px;
  min-width: 32px;
  padding: 0px;
  background: #eeeeee40;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  border: 1px dashed #eeeeee;
  filter: grayscale(1);
  transition: filter 200ms ease-in;

  &:hover {
    filter: grayscale(0);
  }
`;

const CustomRenderOption = ({ option }) => (
  <div
    style={{
      height: 30,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
      }}
    >
      {option?.organization_name && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 4,
          }}
        >
          <Box height={10} width={10} color="slategrey" />
          <span
            style={{
              fontSize: 11,
              color: "slategrey",
              fontWeight: 500,
              lineHeight: 1.3,
              textTransform: "uppercase",
            }}
          >
            {option?.organization_name} Organization
          </span>
        </div>
      )}
      <span
        style={{
          fontSize: 12,
          color: "black",
          fontWeight: 500,
          lineHeight: 1.3,
        }}
      >
        {option?.name}
      </span>
    </div>
    <div
      style={{
        padding: "2px 4px",
        background: "#eeeeee",
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span
        style={{
          fontSize: 10,
          color: "slategrey",
          fontWeight: 600,
          whiteSpace: "nowrap",
        }}
      >
        {option?.permissions_template_name}
      </span>
    </div>
  </div>
);

const DisguiseModal = ({ dispatch, handleClose }) => {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selected, setSelected] = useState(null);

  const [clearCompanies, setClearCompanies] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [switching, setSwitching] = useState(false);
  const [error, setError] = useState(false);

  const [searchText] = useDebounce(search, 300);
  const history = useHistory();

  const getSearchResults = async () => {
    try {
      const { data } = await API.get(
        `/dashboard/account/superuser-company-switch/?search=${searchText}`,
      );
      setSearchResults(data?.companies);
    } catch (error) {
      setError("Error getting search results");
    }
  };

  useEffect(() => {
    if (searchText.length < 3) return undefined;

    getSearchResults();
  }, [searchText]);

  const handleDisguise = async () => {
    try {
      setSwitching(true);

      await API.post("/dashboard/account/superuser-company-switch/", {
        company_id: selected,

        read_only: readOnly,
        clear_current_companies: clearCompanies,
      });
      clearSWRKeys();
      dispatch({ type: ACTIONS.REFRESH_CONFIG });
      history.push("/home/");
    } catch (error) {
      setSwitching(false);
      setError("Error disguising you.");
    }
  };

  return (
    <ModalWrapper>
      <span style={{ fontSize: 16, fontWeight: 600 }}>Superuser Disguise</span>
      <div style={{ height: 12 }} />

      <Autocomplete
        autoFocus={true}
        size="small"
        filterOptions={(x) => x}
        fullWidth
        onClose={() => {
          setSearchResults([]);
        }}
        onInputChange={(e) => {
          return e.target.innerText
            ? setSearch(e.target.innerText)
            : setSearch("");
        }}
        renderOption={(option) => <CustomRenderOption option={option} />}
        onChange={(_, option) => setSelected(option?.id)}
        getOptionLabel={(option) => option.name}
        noOptionsText={
          search.length > 2
            ? "No companies found for search..."
            : "Type to search..."
        }
        options={searchResults}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            label="Search"
            color="secondary"
            fullWidth
            // placeholder="Type To Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
      />
      <div style={{ height: 10 }} />
      <Collapse in={selected} style={{ width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // paddingLeft: 12,
              padding: "4px 12px",
              borderRadius: 4,
              background: "#eeeeee",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                color: readOnly ? "#337ab7" : "slategrey",
                fontSize: 12,
              }}
            >
              Read Only in this company?
            </span>
            <Switch
              checked={readOnly}
              size="small"
              onChange={() => setReadOnly((p) => !p)}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // paddingLeft: 12,

              padding: "4px 12px",
              width: "100%",
              borderRadius: 4,
              background: "#eeeeee",
            }}
          >
            <span
              style={{
                fontWeight: 500,
                color: clearCompanies ? "#337ab7" : "slategrey",
                fontSize: 12,
              }}
            >
              Clear currently managed companies after the switch?
            </span>
            <Switch
              checked={clearCompanies}
              onChange={() => setClearCompanies((p) => !p)}
              size="small"
            />
          </div>
        </div>
      </Collapse>
      <div style={{ height: 10 }} />

      <Button
        size="small"
        variant="contained"
        color="secondary"
        style={{ marginLeft: "auto" }}
        disabled={!selected || switching}
        onClick={handleDisguise}
      >
        Disguise
      </Button>
      {error && (
        <>
          <div style={{ height: 12 }} />
          <Alert
            severity="error"
            onClose={() => setError(null)}
            style={{ border: "1px solid firebrick", width: "100%" }}
          >
            <p style={{ fontWeight: 600, fontSize: 14 }}>Error</p>
            <p>{error}</p>
          </Alert>
        </>
      )}
    </ModalWrapper>
  );
};

const SuperDisguise = ({ dispatch }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip arrow title="Superuser Disguise">
        <SuperDisguiseButton onClick={() => setOpen(true)}>
          <span>
            {/* 🥷 */}
            🕵️
          </span>
        </SuperDisguiseButton>
      </Tooltip>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <DisguiseModal
            handleClose={() => setOpen(false)}
            dispatch={dispatch}
          />
        </div>
      </Modal>
    </>
  );
};

export default SuperDisguise;
